<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <div style="width:100%;background-color:#508caa;margin-bottom: 15px;color: #fff;padding: 20px">修改密码时，密码长度不得小于8位，且必须同时包含字母数字组合；新密码与老密码不得相同！<br>

      </div>
      <div>
        <div>
          <el-form
              ref="ruleFormRef"
              :model="ruleForm"
              :rules="rules"
              label-width="240px"
              class="demo-ruleForm"
              status-icon
              style="width: 90%; margin: 0 auto;"
          >
            <el-form-item label="请输入旧密码:" prop="loginName">
              <el-input v-model="ruleForm.oldPwd" placeholder="旧密码" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入新密码:" prop="pwd">
              <el-input v-model="ruleForm.pwd" type="password" placeholder="新密码（格式:字母+数字组合）" autocomplete="off" />
            </el-form-item>
            <el-form-item label="请确认新密码:" prop="checkPass">
              <el-input v-model="ruleForm.checkPass" type="password" placeholder="确认密码（格式:字母+数字组合）" autocomplete="off" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="background-color: #1c69a2" @click="submitForm('ruleFormRef')" size="large" :loading="loading">保存</el-button>
              <el-button @click="goBack" size="large">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: "changePassword",
  data(){
    return{
      loading: false,
      ruleForm: {
      },
      rules: {
        oldPwd: [{ required: true, message: '请填写旧密码', trigger: 'blur' }],
        pwd: [{ required: true, message: '请填写新密码', trigger: 'blur' }],
        checkPass: [{ required: true, message: '请填写新密码', trigger: 'blur' }],

      },
    }
  },
  mounted() {
  },
  methods:{
    goBack:function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>